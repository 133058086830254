import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'demo',

  ecomm: {
    ...base.ecomm
  },
  ngfeBaseUrl: 'https://demo.sit.ordering.wingstop.com',
  featureFlags: {
    ...base.featureFlags,
    enableSentry: true,
    enableSentryFeedbackDialog: true
  },
};
